export default function ChatIcon({ className }) {
  return (
    <svg
      className={className}
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.51285 19.3026C9.84991 19.0619 9.92798 18.5934 9.68722 18.2564C9.44647 17.9193 8.97805 17.8413 8.64099 18.082L9.51285 19.3026ZM5.84615 21H5.09615C5.09615 21.2809 5.25316 21.5383 5.50297 21.6669C5.75277 21.7954 6.05347 21.7736 6.28208 21.6103L5.84615 21ZM5.84615 16.3846H6.59615C6.59615 15.9704 6.26037 15.6346 5.84615 15.6346V16.3846ZM2.61538 16.3846V17.1346V16.3846ZM1 14.8462H0.25H1ZM1 2.53846H0.25H1ZM2.61538 1V0.25V1ZM21.3846 1V0.25V1ZM22.25 7.10547C22.25 7.51968 22.5858 7.85547 23 7.85547C23.4142 7.85547 23.75 7.51968 23.75 7.10547H22.25ZM8.64099 18.082L5.41023 20.3897L6.28208 21.6103L9.51285 19.3026L8.64099 18.082ZM6.59615 21V16.3846H5.09615V21H6.59615ZM5.84615 15.6346H2.61538V17.1346H5.84615V15.6346ZM2.61538 15.6346C2.37438 15.6346 2.15017 15.5431 1.99038 15.3909L0.955894 16.4771C1.40198 16.902 1.99953 17.1346 2.61538 17.1346V15.6346ZM1.99038 15.3909C1.83182 15.2399 1.75 15.0431 1.75 14.8462H0.25C0.25 15.4652 0.50856 16.0511 0.955894 16.4771L1.99038 15.3909ZM1.75 14.8462V2.53846H0.25V14.8462H1.75ZM1.75 2.53846C1.75 2.34148 1.83182 2.14471 1.99038 1.99371L0.955894 0.907502C0.508561 1.33353 0.25 1.91939 0.25 2.53846H1.75ZM1.99038 1.99371C2.15017 1.84152 2.37438 1.75 2.61538 1.75V0.25C1.99953 0.25 1.40198 0.482654 0.955894 0.907502L1.99038 1.99371ZM2.61538 1.75H21.3846V0.25H2.61538V1.75ZM21.3846 1.75C21.6256 1.75 21.8498 1.84152 22.0096 1.99371L23.0441 0.907502C22.598 0.482655 22.0005 0.25 21.3846 0.25V1.75ZM22.0096 1.99371C22.1682 2.14471 22.25 2.34149 22.25 2.53846H23.75C23.75 1.91939 23.4914 1.33353 23.0441 0.907502L22.0096 1.99371ZM22.25 2.53846V7.10547H23.75V2.53846H22.25Z"
        fill="#000"
      />
      <path
        d="M6.34398 10.0371C5.97788 9.84336 5.52402 9.98308 5.33027 10.3492C5.13652 10.7153 5.27624 11.1691 5.64235 11.3629L6.34398 10.0371ZM8.85165 12.5091C9.25766 12.5912 9.65329 12.3285 9.73531 11.9225C9.81734 11.5165 9.55469 11.1209 9.14868 11.0389L8.85165 12.5091ZM5.64235 11.3629C6.65157 11.897 7.73242 12.283 8.85165 12.5091L9.14868 11.0389C8.17056 10.8412 7.22597 10.5039 6.34398 10.0371L5.64235 11.3629Z"
        fill="#000"
      />
      <path
        d="M8.75 5C8.75 4.58579 8.41421 4.25 8 4.25C7.58579 4.25 7.25 4.58579 7.25 5H8.75ZM7.25 6.5C7.25 6.91421 7.58579 7.25 8 7.25C8.41421 7.25 8.75 6.91421 8.75 6.5H7.25ZM7.25 5V6.5H8.75V5H7.25Z"
        fill="#000"
      />
      <path
        d="M16.75 5C16.75 4.58579 16.4142 4.25 16 4.25C15.5858 4.25 15.25 4.58579 15.25 5H16.75ZM15.25 6.5C15.25 6.91421 15.5858 7.25 16 7.25C16.4142 7.25 16.75 6.91421 16.75 6.5H15.25ZM15.25 5V6.5H16.75V5H15.25Z"
        fill="#000"
      />
      <path
        d="M20.5 20.3333V19.5833C20.0858 19.5833 19.75 19.9191 19.75 20.3333H20.5ZM20.5 23L20.0654 23.6112C20.2941 23.7739 20.5945 23.7952 20.8439 23.6665C21.0933 23.5378 21.25 23.2807 21.25 23H20.5ZM16.75 20.3333L17.1846 19.7221C17.0577 19.6318 16.9058 19.5833 16.75 19.5833V20.3333ZM22 19.5833H20.5V21.0833H22V19.5833ZM19.75 20.3333V23H21.25V20.3333H19.75ZM20.9346 22.3888L17.1846 19.7221L16.3154 20.9445L20.0654 23.6112L20.9346 22.3888ZM16.75 19.5833H14V21.0833H16.75V19.5833ZM13.75 19.3333V14H12.25V19.3333H13.75ZM14 13.75H22V12.25H14V13.75ZM22.25 14V19.3333H23.75V14H22.25ZM22 13.75C22.1381 13.75 22.25 13.8619 22.25 14H23.75C23.75 13.0335 22.9665 12.25 22 12.25V13.75ZM14 19.5833C13.8619 19.5833 13.75 19.4714 13.75 19.3333H12.25C12.25 20.2998 13.0335 21.0833 14 21.0833V19.5833ZM13.75 14C13.75 13.8619 13.8619 13.75 14 13.75V12.25C13.0335 12.25 12.25 13.0335 12.25 14H13.75ZM22 21.0833C22.9665 21.0833 23.75 20.2998 23.75 19.3333H22.25C22.25 19.4714 22.1381 19.5833 22 19.5833V21.0833Z"
        fill="#000"
      />
    </svg>
  );
}
