import "./App.css";

import { useEffect, useState } from "react";
import WidgetOpenButton from "./components/WidgetOpenButton";
import Widget from "./components/Widget";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [siteId, setSiteId] = useState(null);

  useEffect(() => {
    const container = document.getElementById("pubtrawlr-ai-chat-widget");
    if (!container) return;
    const tempSiteId = container.getAttribute("site-id");
    if (!tempSiteId) return;
    setSiteId(tempSiteId);
  }, []);

  return (
    <>
      <Widget
        isOpen={isOpen}
        siteId={siteId}
        onClick={() => setIsOpen(false)}
      />
      <WidgetOpenButton onClick={() => setIsOpen(true)} isOpen={isOpen} />
    </>
  );
}

export default App;
